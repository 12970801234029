import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadVGSCollect } from '@vgs/collect-js'
import { Composition } from 'atomic-layout'
import { every, isEmpty, pickBy } from 'lodash'
import styled, { css } from 'styled-components'
import toast from 'react-hot-toast'

import { Button, Input, IonIcon, Select, Text } from 'src/atoms'
import { astraTheme } from 'src/styles/theme'
import { vgsCountryOptions } from 'src/utils/countryOptions'
import {
  selectClient,
  selectFirebaseToken,
  selectUser,
} from 'src/features/auth/authSlice'
import AddressEditRow from './AddressEditRow'
import { getAddresses, selectDefaultAddress } from './addressesSlice'
import { getCards } from './cardsSlice'
import { getStringLengthRegex } from './helpers'

import './vgsStyle.css'
import { Address } from './types'
import { Box, Flex, Tooltip } from '@chakra-ui/react'
import Spinner from 'src/components/Spinner'
import { CLIENT_ID_HEADER, USER_ID_HEADER } from 'src/utils/api'
import { User, UserType } from 'src/features/auth/types'
import { vgsStateOptions } from 'src/utils/stateOptions'
import logError from 'src/utils/logError'

const REACT_APP_VGS_ENVIRONMENT = process.env.REACT_APP_VGS_ENVIRONMENT
const REACT_APP_VGS_VAULT_ID = process.env.REACT_APP_VGS_VAULT_ID

const VALID_CARD_TYPES = ['visa', 'visaelectron', 'maestro', 'mastercard']
const CARD_TYPE_ERROR = 'Only Visa and Mastercard are supported at this time'

const INVALID_CHARACTERS_REGEX = /[^A-Za-z0-9^ !_\-@'`\t()#$%&,*:.-\\]+/

/**
 * Allows us to maintain the form height while adding in a loading spinner in lieu of the form
 * when the form is being submitted.
 */

const StyledSelect = styled(Select)`
  height: 48px;
  max-width: 20rem;
`

const CardFormOverlay = ({
  isSubmitting,
  children,
}: {
  isSubmitting: boolean
  children: ReactNode
}) => {
  return (
    <Box style={{ position: 'relative' }}>
      {children}
      {isSubmitting && (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <Spinner />
        </Box>
      )}
    </Box>
  )
}

const vgsInputStyle = css`
  border: 1px solid
    ${(props: any) =>
      props.error ? props.theme.colors.error : props.theme.colors.border};
  border-radius: 4px;
  font-size: 1rem;
  padding: 0.75rem;
  max-width: 20rem;
  width: 100%;
  height: 48px;
  position: relative;
`
const VgsInput = styled.div<{ id: string; error?: any }>`
  ${vgsInputStyle}
`

const vgsStyles = {
  fontFamily: astraTheme.fonts.book,
  fontSize: '1rem',
  color: astraTheme.colors.text,
}

const displayNone = { display: 'none' }

interface VgsCollectFormProps {
  debitDirect: boolean
  onClose: () => void
}

const VgsCollectForm: React.FC<VgsCollectFormProps> = ({
  debitDirect,
  onClose,
}) => {
  const dispatch = useDispatch()

  const clientId = useSelector(selectClient).clientId
  const user = useSelector(selectUser) as User
  const defaultAddress = useSelector(selectDefaultAddress) as Address
  const firebaseToken = useSelector(selectFirebaseToken)
  const isBusinessUser = user.userType === UserType.BUSINESS

  const [form, setForm] = useState<any>({})
  const [isAddressEditing, setIsAddressEditing] = useState(false)
  const [isFormLoaded, setIsFormLoaded] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useState<any>({})
  const [selectedCountry, setSelectedCountry] = useState(
    defaultAddress?.country || 'US',
  )
  const [selectedState, setSelectedState] = useState(
    defaultAddress?.state || '',
  )

  useEffect(() => {
    setIsAddressEditing(isEmpty(defaultAddress))
  }, [defaultAddress])

  const initCollectForm = useCallback(
    (collect: any) => {
      const vgsForm = collect.init((state: { card_number: any }) => {
        const errors = pickBy(
          state,
          value => value?.errorMessages?.length > 0 && value?.isTouched,
        )

        let isValid = every(state, { isValid: true })

        const { cardType } = state.card_number || {}

        if (
          !errors.card_number &&
          cardType &&
          !VALID_CARD_TYPES.includes(cardType)
        ) {
          errors.card_number = {
            errorMessages: [CARD_TYPE_ERROR],
          }
          isValid = false
        }

        setFormErrors(errors)
        setIsFormValid(isValid)
      })

      vgsForm
        .field('#first_name', {
          type: 'text',
          name: 'first_name',
          placeholder: 'First Name',
          validations: ['required', getStringLengthRegex(2, 40)],
          css: vgsStyles,
        })
        .replacePattern(INVALID_CHARACTERS_REGEX)

      vgsForm
        .field('#last_name', {
          type: 'text',
          name: 'last_name',
          placeholder: 'Last Name',
          validations: ['required', getStringLengthRegex(2, 40)],
          css: vgsStyles,
        })
        .replacePattern(INVALID_CHARACTERS_REGEX)

      vgsForm.field('#card_number', {
        type: 'card-number',
        name: 'card_number',
        placeholder: 'Card Number',
        autoComplete: 'cc-number',
        showCardIcon: true,
        validations: ['required', 'validCardNumber'],
        css: vgsStyles,
      })

      vgsForm.field('#expiration_date', {
        type: 'card-expiration-date',
        name: 'expiration_date',
        placeholder: 'MM / YY',
        autoComplete: 'cc-exp',
        validations: ['required', 'validCardExpirationDate'],
        yearLength: 2,
        css: vgsStyles,
      })

      vgsForm.field('#card_security_code', {
        type: 'card-security-code',
        name: 'card_security_code',
        placeholder: 'CVC',
        autoComplete: 'cc-csc',
        validations: ['required', 'validCardSecurityCode'],
        css: vgsStyles,
      })

      vgsForm
        .field('#street_line_1', {
          type: 'text',
          name: 'street_line_1',
          placeholder: 'Address Line 1',
          defaultValue: defaultAddress?.street_line_1,
          validations: ['required', getStringLengthRegex(2, 100)],
          css: vgsStyles,
        })
        .replacePattern(INVALID_CHARACTERS_REGEX)

      vgsForm
        .field('#street_line_2', {
          type: 'text',
          name: 'street_line_2',
          placeholder: 'Address Line 2',
          defaultValue: defaultAddress?.street_line_2,
          css: vgsStyles,
        })
        .replacePattern(INVALID_CHARACTERS_REGEX)

      vgsForm
        .field('#city', {
          type: 'text',
          name: 'city',
          placeholder: 'City',
          defaultValue: defaultAddress?.city,
          validations: ['required', getStringLengthRegex(2, 20)],
          css: vgsStyles,
        })
        .replacePattern(INVALID_CHARACTERS_REGEX)

      vgsForm
        .field('#zip_code', {
          type: 'text',
          name: 'zip_code',
          placeholder: 'Postal Code',
          defaultValue: defaultAddress?.zip_code,
          validations: ['required', '/^[A-Z0-9 ]+$/'],
          css: vgsStyles,
        })
        .replacePattern(INVALID_CHARACTERS_REGEX)

      setForm(vgsForm)
      setIsFormLoaded(true)
    },
    [defaultAddress, setFormErrors, setIsFormValid, setForm, setIsFormLoaded],
  )

  useEffect(() => {
    const loadCollectForm = async () => {
      try {
        const collect = await loadVGSCollect({
          vaultId: REACT_APP_VGS_VAULT_ID as string,
          environment: REACT_APP_VGS_ENVIRONMENT as string,
          version: '2.24.6',
        })
        initCollectForm(collect)
      } catch (e) {
        logError(e)
        toast.error('Error loading form. Please try again.')
      }
    }

    loadCollectForm()
  }, [initCollectForm])

  const handleStateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setSelectedState(e.target.value)
  }

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(e.target.value)
    setSelectedState('')
  }

  const renderStateField = () => {
    if (selectedCountry === 'US') {
      return (
        <Select
          value={selectedState}
          onChange={handleStateChange}
          placeholder='State'
          style={{ minWidth: '151px' }}
        >
          {vgsStateOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      )
    } else {
      return (
        <Input
          type='text'
          value={selectedState}
          onChange={handleStateChange}
          placeholder='Province/Region'
        />
      )
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const FORM = form as any

      FORM.submit(
        'app/v1/cards',
        {
          headers: {
            Authorization: `Token ${firebaseToken}`,
            [CLIENT_ID_HEADER]: clientId || '',
            [USER_ID_HEADER]: user.userId || '',
          },
          data: {
            country: selectedCountry,
            oauth_client_id_created_by: clientId || '',
            state: selectedState,
          },
        },
        (status: number, response: any) => {
          setIsSubmitting(false)

          if (!status || status !== 200) {
            // Handle different error types
            if (typeof response === 'string') {
              toast.error(response)
            } else if (typeof response?.description === 'string') {
              toast.error(response.description)
            } else if (Object.values(response?.description).length) {
              let message = ''
              const messages = Object.values(response.description).map(
                ([value]: any) => value,
              )

              if (messages.length) {
                message = messages.join(' ')
              }
              toast.error(message)
            } else {
              toast.error('Invalid request')
            }
          } else {
            toast.success('Debit card is now connected.')

            if (!debitDirect) {
              dispatch(getCards({ clientId }))
              dispatch(getAddresses())
            }

            onClose()
          }
        },
        (formErrors: any) => {
          setFormErrors(formErrors)
          setIsSubmitting(false)
          toast.error(
            'Your card details seem to be incorrect. Please try again.',
          )
        },
      )
    } catch (e) {
      // Handle any errors that occur in the try block
      const error = e as any
      setIsSubmitting(false)
      toast.error(error.message)
    }
  }

  return (
    <form id='vgs-form' onSubmit={handleSubmit}>
      <Composition
        areas={`
          header
          info
          address
          submit
          cancel
        `}
        gap={10}
      >
        {({ Header, Info, Address, Submit, Cancel }: any) => (
          <>
            <Header alignItems='center'>
              <Text fontFamily='heavy' size='large'>
                Enter Your{' '}
                {isBusinessUser ? 'Business Debit Card' : 'Debit Card'} Details
              </Text>
              <Text fontFamily='light' size='small'>
                Only cards owned by you may be connected to your profile.
              </Text>
            </Header>
            <CardFormOverlay isSubmitting={isSubmitting}>
              <Info marginTop={5}>
                <Composition
                  areas={`
                  infoHeader infoHeader
                  firstName firstName
                  lastName lastName
                  cardNumber cardNumber
                  cardExpiration cardCvc
                `}
                  gap={10}
                >
                  {({
                    InfoHeader,
                    FirstName,
                    LastName,
                    CardNumber,
                    CardExpiration,
                    CardCvc,
                  }: any) => (
                    <>
                      <InfoHeader flex alignItems='center' marginVertical={5}>
                        <Text fontFamily='medium' size='medium'>
                          Card Info
                        </Text>
                        {clientId && (
                          <div style={displayNone}>
                            <VgsInput id='client_id' />
                          </div>
                        )}
                      </InfoHeader>
                      <FirstName>
                        <VgsInput
                          id='first_name'
                          error={formErrors?.first_name?.errorMessages}
                        />
                      </FirstName>
                      <LastName>
                        <VgsInput
                          id='last_name'
                          error={formErrors?.last_name?.errorMessages}
                        />
                      </LastName>
                      <CardNumber>
                        <VgsInput
                          id='card_number'
                          error={formErrors?.card_number?.errorMessages}
                        />
                        {formErrors?.card_number?.errorMessages?.[0] ===
                          CARD_TYPE_ERROR && (
                          <Text
                            size='xsmall'
                            style={{ padding: '2px' }}
                            color='error'
                          >
                            {CARD_TYPE_ERROR}
                          </Text>
                        )}
                      </CardNumber>
                      <CardExpiration flex>
                        <VgsInput
                          id='expiration_date'
                          error={formErrors?.expiration_date?.errorMessages}
                        />
                      </CardExpiration>
                      <CardCvc flex>
                        <VgsInput
                          id='card_security_code'
                          error={formErrors?.card_security_code?.errorMessages}
                        />
                      </CardCvc>
                    </>
                  )}
                </Composition>
              </Info>
            </CardFormOverlay>
            <Address>
              <Composition
                areas={`
                      addressHeader addressHeader
                      streetLine1 streetLine1
                      streetLine2 streetLine2
                      city state
                      zipCode zipCode
                      country country
                      addressSummary addressSummary
                    `}
                gap={isAddressEditing ? 10 : '0px 10px'}
              >
                {({
                  AddressHeader,
                  StreetLine1,
                  StreetLine2,
                  City,
                  State,
                  ZipCode,
                  Country,
                  AddressSummary,
                }: any) => (
                  <>
                    <AddressHeader
                      flex
                      marginVertical={5}
                      flexDirection='column'
                    >
                      <Flex>
                        <Text
                          fontFamily='medium'
                          size='medium'
                          style={{ marginRight: 5 }}
                        >
                          Card Address
                        </Text>
                        <Tooltip
                          label='The physical address on file at the issuing bank.'
                          placement='right-start'
                        >
                          <Flex alignItems='center'>
                            <IonIcon
                              name='information-circle-outline'
                              size={18}
                              style={{ cursor: 'pointer' }}
                            />
                          </Flex>
                        </Tooltip>
                      </Flex>
                      <Text fontFamily='light' size='small'>
                        Enter card billing address
                      </Text>
                    </AddressHeader>
                    <StreetLine1>
                      <div style={isAddressEditing ? {} : displayNone}>
                        <VgsInput id='street_line_1' />
                      </div>
                    </StreetLine1>
                    <StreetLine2>
                      <div style={isAddressEditing ? {} : displayNone}>
                        <VgsInput id='street_line_2' />
                      </div>
                    </StreetLine2>
                    <City>
                      <div style={isAddressEditing ? {} : displayNone}>
                        <VgsInput id='city' />
                      </div>
                    </City>
                    <State>
                      <div style={isAddressEditing ? {} : displayNone}>
                        {renderStateField()}
                      </div>
                    </State>
                    <ZipCode>
                      <div style={isAddressEditing ? {} : displayNone}>
                        <VgsInput id='zip_code' />
                      </div>
                    </ZipCode>
                    <Country>
                      <div style={isAddressEditing ? {} : displayNone}>
                        <StyledSelect
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          placeholder='Country'
                        >
                          {vgsCountryOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </StyledSelect>
                      </div>
                    </Country>
                    <AddressSummary
                      onClick={() => {
                        setIsAddressEditing(true)
                      }}
                    >
                      <div style={isAddressEditing ? displayNone : {}}>
                        <AddressEditRow address={defaultAddress} />
                      </div>
                    </AddressSummary>
                  </>
                )}
              </Composition>
            </Address>
            <Submit marginTop={10}>
              <Button
                type='submit'
                disabled={!isFormLoaded || !isFormValid || isSubmitting}
                fullWidth
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Submit>
            {!debitDirect && (
              <Cancel>
                <Button
                  backgroundColor='background'
                  color='primary'
                  disabled={isSubmitting}
                  onClick={(e: any) => {
                    e.preventDefault()
                    form.reset()
                    onClose()
                  }}
                  fullWidth
                >
                  Cancel
                </Button>
              </Cancel>
            )}
          </>
        )}
      </Composition>
    </form>
  )
}

export default VgsCollectForm
